import React, { useState, useEffect } from 'react';
import SelectBox from "devextreme-react/select-box";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

const ActionDropdown = ({ permissions, selectedRowKeys, transactionType, handleBulkReject, handleBulkRollback, handleBulkHold, handleBulkCancel, setPopupVisibility, isForFormView = false, actionDropdownDatasource = [], callback }) => {
    const [dataSource, setDataSource] = useState([]);

    useEffect(() => {
        if (!isForFormView)
            generateDataSource();
    }, [permissions, selectedRowKeys, transactionType]);

    useEffect(() => {
        if (actionDropdownDatasource.length > 0) {
            setDataSource(actionDropdownDatasource);
        }
    }, [actionDropdownDatasource]);

    const generateDataSource = () => {
        if (actionDropdownDatasource.length === 0) {
            const items = [];

            if (permissions && permissions.canApprove && selectedRowKeys.some(e => e.needApproval && e.status === null && e.actionedByApproverId === null && e.overrideUserId === null)) items.push({
                text: 'Rollback',
                onClick: handleBulkRollback
            });
            if (permissions && permissions.canApprove && selectedRowKeys.some(e => e.needApproval)) items.push({
                text: 'Reject',
                onClick: handleBulkReject
            });

            /*if (permissions && !permissions.canOverride && selectedRowKeys.some(e => e.status && e.canHoldOrReactivate && e.cancelled !== true && !e.canHoldForApprover)) items.push({
                text: 'Hold Request',
                onClick: () => handleBulkHold(true, false, false, 'canHoldOrReactivate')
            });
            if (permissions && permissions.canOverride && selectedRowKeys.some(e => e.canHoldForOverrideUser)) items.push({
                text: 'Hold Request',
                onClick: () => handleBulkHold(true, false, true, 'canHoldForOverrideUser')
            });
            if (permissions && permissions.canApprove && selectedRowKeys.some(e => e.canHoldForApprover && !e.canHoldForOverrideUser)) items.push({
                text: 'Hold Request',
                onClick: () => handleBulkHold(true, true, false)
            });*/

            if (permissions && !permissions.canOverride && selectedRowKeys.some(e => e.status && e.canReactivate && e.cancelled !== true && !e.canReactivateForApprover)) items.push({
                text: 'Reactivate Request',
                onClick: () => handleBulkHold(false, false, false, 'canReactivate')
            });
            if (permissions && permissions.canApprove && selectedRowKeys.some(e => e.canReactivateForOverrideUser)) items.push({
                text: 'Reactivate Request',
                onClick: () => handleBulkHold(false, false, true, 'canReactivateForOverrideUser')
            });
            if (permissions && permissions.canOverride && selectedRowKeys.some(e => e.canReactivateForApprover)) items.push({
                text: 'Reactivate Request',
                onClick: () => handleBulkHold(false, true, false, 'canReactivateForApprover')
            });

            if (permissions && permissions.canOverride && selectedRowKeys.some(e => e.actionedByApproverId === null && e.overrideUserId === null && !e.needApproval && e.cStatus !== "Closed" && !e.onHold && e.status && e.canCancelOverrideUser)) {
                const data = selectedRowKeys.filter(e => e.actionedByApproverId === null && !e.needApproval && e.cStatus !== "Closed" && !e.onHold && e.status && e.canCancelOverrideUser)
                const transIDS = _.map(data, 'id')
                items.push({
                    text: 'Cancel Request',
                    onClick: () => handleBulkCancel(transIDS, transactionType, false, false, () => callback(transactionType), true)
                });
            }
            if (permissions && permissions.canCreate && !permissions.canOverride && selectedRowKeys.some(e => e.actionedByApproverId === null && e.overrideUserId === null && !e.needApproval && e.cStatus !== "Closed" && !e.onHold && e.status && e.canCancel)) {
                const data = selectedRowKeys.filter(e => e.actionedByApproverId === null && !e.needApproval && e.cStatus !== "Closed" && !e.onHold && e.status && e.canCancel)
                const transIDS = _.map(data, 'id')
                items.push({
                    text: 'Cancel Request',
                    onClick: () => handleBulkCancel(transIDS, transactionType, false, false, () => callback(transactionType))
                });
            }
            if (permissions && permissions.canCreate && transactionType === "hc_transaction") {
                items.push({
                    text: 'Clone',
                    onClick: () => setPopupVisibility(true)
                });
            }

            setDataSource(items);
        }
    };

    return (
        <>
            {
                dataSource.length
                    ?
                    <SelectBox
                        dataSource={dataSource}
                        displayExpr="text"
                        valueExpr="text"
                        placeholder="Additional Actions"
                        style={{ width: "170px" }}
                    /> : ""
            }
        </>

    );
};

export default ActionDropdown;
